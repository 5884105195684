@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,500|Ubuntu:400,500&display=swap');
@import '~scss/constants.scss';
@import '~scss/mixins.scss';

body {
  margin: 0;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: $body_background_color;

  @include breakpoint($phone) {
    font-size: 12px;
  }
}