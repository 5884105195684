@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,500|Ubuntu:400,500&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: #121212; }
  @media only screen and (max-width: 450px) {
    body {
      font-size: 12px; } }

button {
  border: none;
  background: #E83A3D;
  border-radius: 36px;
  font-size: 22px;
  color: #fff;
  padding: 18px 36px;
  font-family: 'Ubuntu';
  font-weight: 400;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  background: #E83A3D -webkit-gradient(linear, right top, left top, from(#E83A3D), color-stop(30%, #E83A3D), color-stop(40%, #FE7A7D), color-stop(60%, #E83A3D));
  background: #E83A3D linear-gradient(to left, #E83A3D 0%, #E83A3D 30%, #FE7A7D 40%, #E83A3D 60%);
  -webkit-animation: slide 10s infinite;
          animation: slide 10s infinite;
  background-position: -140px; }

#root {
  background: #121212;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 0; }

#root::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: url(/static/media/page-section-bg.1c7c58ee.png);
  background-size: 300px;
  opacity: 0.06;
  position: absolute;
  top: 0;
  z-index: 0; }

#root::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 18, 18, 0.2)), to(#121212));
  background: linear-gradient(to bottom, rgba(18, 18, 18, 0.2), #121212);
  position: absolute;
  top: 0;
  z-index: 1; }

.scene-icon-pile {
  background: #121212;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1; }

.scene-icon-pile::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: url(/static/media/page-section-bg.1c7c58ee.png);
  background-size: 300px;
  opacity: 0.06;
  position: absolute;
  top: 0;
  z-index: 1; }

.scene-icon-pile::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 18, 18, 0.2)), to(#121212));
  background: linear-gradient(to bottom, rgba(18, 18, 18, 0.2), #121212);
  position: absolute;
  top: 0;
  z-index: 2; }

.scene-icon-pile canvas {
  position: absolute;
  z-index: 10; }

.page-section {
  position: relative;
  overflow: auto; }

.page-section-inner {
  position: relative; }

.page-section {
  z-index: 2;
  text-align: center;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .page-section h1 {
    font-family: 'Poppins';
    font-weight: 500;
    color: white;
    font-size: 2.4em;
    z-index: 3;
    position: relative; }
    @media only screen and (max-width: 450px) {
      .page-section h1 {
        font-size: 2em; } }
  .page-section h2 {
    font-size: 1.8em;
    font-family: 'Poppins';
    font-weight: 400;
    z-index: 3;
    position: relative;
    max-width: 600px;
    padding: 0 18px;
    margin: 0 auto; }
    @media only screen and (max-width: 450px) {
      .page-section h2 {
        font-size: 1.7em;
        padding: 0 12px; } }
  .page-section .profile-pic {
    background-image: url(/static/media/profile-photo.2579767d.png);
    background-size: 240px 240px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 240px;
    height: 240px;
    z-index: 3;
    position: relative;
    margin: 96px auto 40px; }
    @media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
      .page-section .profile-pic {
        background-image: url(/static/media/profile-photo@2x.3b4c26f7.png); } }
    @media only screen and (max-width: 450px) {
      .page-section .profile-pic {
        background-image: url(/static/media/profile-photo.2579767d.png);
        background-size: 156px 156px;
        background-position: center center;
        background-repeat: no-repeat;
        margin: 66px auto 24px;
        width: 156px;
        height: 156px; } }
  .page-section button {
    margin-top: 48px; }
  .page-section p {
    font-size: 1.3em;
    margin: 18px 0;
    color: #999; }

.scene-icon-pile .profile-pic {
  background-image: url(/static/media/profile-photo-2.67952884.png);
  background-size: 240px 240px;
  background-position: center center;
  background-repeat: no-repeat; }
  @media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
    .scene-icon-pile .profile-pic {
      background-image: url(/static/media/profile-photo-2@2x.b6d8b379.png); } }
  @media only screen and (max-width: 450px) {
    .scene-icon-pile .profile-pic {
      background-image: url(/static/media/profile-photo-2.67952884.png);
      background-size: 156px 156px;
      background-position: center center;
      background-repeat: no-repeat; } }

.scene-section-icon {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: -96px;
  z-index: 100; }

.page-section.minimized .profile-pic {
  position: relative;
  -webkit-animation: 0.3s minimizePageHeader ease forwards;
          animation: 0.3s minimizePageHeader ease forwards; }

.page-section.minimized .profile-pic:hover {
  display: none; }

.page-section.minimized h1, .page-section.minimized h2, .page-section.minimized .button, .page-section.minimized p {
  -webkit-animation: 0.3s fadeOutScale ease forwards;
          animation: 0.3s fadeOutScale ease forwards; }

.page-section.maximized .profile-pic {
  -webkit-animation: 0.3s maximizePageHeader ease forwards;
          animation: 0.3s maximizePageHeader ease forwards; }

.page-section.maximized h1, .page-section.maximized h2, .page-section.maximized .button, .page-section.maximized p {
  -webkit-animation: 0.3s fadeInScale ease forwards;
          animation: 0.3s fadeInScale ease forwards; }

.page-section.initial .profile-pic {
  -webkit-animation: 0.3s loadPageHeader ease forwards;
          animation: 0.3s loadPageHeader ease forwards; }

.page-section.initial h1, .page-section.initial h2, .page-section.initial .button, .page-section.initial p {
  -webkit-animation: 0.3s fadeInScale ease forwards;
          animation: 0.3s fadeInScale ease forwards; }

@-webkit-keyframes minimizePageHeader {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-size: 240px;
    width: 240px;
    height: 240px; }
  100% {
    -webkit-transform: translateY(-42px);
            transform: translateY(-42px);
    width: 72px;
    height: 72px;
    background-size: 72px; } }

@keyframes minimizePageHeader {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    background-size: 240px;
    width: 240px;
    height: 240px; }
  100% {
    -webkit-transform: translateY(-42px);
            transform: translateY(-42px);
    width: 72px;
    height: 72px;
    background-size: 72px; } }

@-webkit-keyframes maximizePageHeader {
  0% {
    -webkit-transform: translateY(-126px);
            transform: translateY(-126px);
    background-size: 72px; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    width: 240px;
    height: 240px;
    background-size: 240px; } }

@keyframes maximizePageHeader {
  0% {
    -webkit-transform: translateY(-126px);
            transform: translateY(-126px);
    background-size: 72px; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    width: 240px;
    height: 240px;
    background-size: 240px; } }

@-webkit-keyframes loadPageHeader {
  0% {
    -webkit-transform: translateY(126px);
            transform: translateY(126px);
    background-size: 72px; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    width: 240px;
    height: 240px;
    background-size: 240px; } }

@keyframes loadPageHeader {
  0% {
    -webkit-transform: translateY(126px);
            transform: translateY(126px);
    background-size: 72px; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    width: 240px;
    height: 240px;
    background-size: 240px; } }

@media only screen and (max-width: 450px) {
  @-webkit-keyframes minimizePageHeader {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      background-size: 156px;
      width: 156px;
      height: 156px; }
    100% {
      -webkit-transform: translateY(-42px);
              transform: translateY(-42px);
      width: 72px;
      height: 72px;
      background-size: 72px; } }
  @keyframes minimizePageHeader {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      background-size: 156px;
      width: 156px;
      height: 156px; }
    100% {
      -webkit-transform: translateY(-42px);
              transform: translateY(-42px);
      width: 72px;
      height: 72px;
      background-size: 72px; } }
  @-webkit-keyframes maximizePageHeader {
    0% {
      -webkit-transform: translateY(-84px);
              transform: translateY(-84px);
      background-size: 72px; }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      width: 156px;
      height: 156px;
      background-size: 156px; } }
  @keyframes maximizePageHeader {
    0% {
      -webkit-transform: translateY(-84px);
              transform: translateY(-84px);
      background-size: 72px; }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      width: 156px;
      height: 156px;
      background-size: 156px; } } }

.zoom-0::after {
  -webkit-animation: 0.3s zoom-0 ease forwards;
          animation: 0.3s zoom-0 ease forwards;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.zoom-1::after {
  -webkit-animation: 0.3s zoom-1 ease forwards;
          animation: 0.3s zoom-1 ease forwards;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

@-webkit-keyframes zoom-1 {
  from {
    background-size: 300px;
    background-position: 0px 0px; }
  to {
    background-size: 200px;
    background-position: 200px 200px; } }

@keyframes zoom-1 {
  from {
    background-size: 300px;
    background-position: 0px 0px; }
  to {
    background-size: 200px;
    background-position: 200px 200px; } }

@-webkit-keyframes zoom-0 {
  from {
    background-size: 200px;
    background-position: 200px 200px; }
  to {
    background-size: 300px;
    background-position: 0px 0px; } }

@keyframes zoom-0 {
  from {
    background-size: 200px;
    background-position: 200px 200px; }
  to {
    background-size: 300px;
    background-position: 0px 0px; } }

@-webkit-keyframes fadeOutScale {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(-96px);
            transform: translateY(-96px);
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@keyframes fadeOutScale {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(-96px);
            transform: translateY(-96px);
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@-webkit-keyframes fadeInScale {
  from {
    opacity: 0;
    -webkit-transform: translateY(-96px);
            transform: translateY(-96px);
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeInScale {
  from {
    opacity: 0;
    -webkit-transform: translateY(-96px);
            transform: translateY(-96px);
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes slide {
  0% {
    background-position: -140px; }
  20% {
    background-position: 80px; }
  100% {
    background-position: 100px; } }

@keyframes slide {
  0% {
    background-position: -140px; }
  20% {
    background-position: 80px; }
  100% {
    background-position: 100px; } }

.page-enter button,
.page-appear button {
  opacity: 0.01;
  -webkit-transform: scale(0.6) translateX(400px);
          transform: scale(0.6) translateX(400px); }

.page-enter .profile-pic,
.page-appear .profile-pic {
  opacity: 0.01;
  -webkit-transform: scale(0.6) translateY(-100px);
          transform: scale(0.6) translateY(-100px); }

.page-enter .scene-icon-pile p,
.page-appear .scene-icon-pile p {
  opacity: 0.01;
  -webkit-transform: scale(0.6) translateX(400px);
          transform: scale(0.6) translateX(400px); }

.page-enter h1,
.page-appear h1 {
  opacity: 0.01;
  -webkit-transform: scale(0.6) translateX(400px);
          transform: scale(0.6) translateX(400px); }

.page-enter h2,
.page-appear h2 {
  opacity: 0.01;
  -webkit-transform: scale(0.6) translateX(400px);
          transform: scale(0.6) translateX(400px); }

.page-appear.page-appear-active button,
.page-enter-active button {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0); }

.page-appear.page-appear-active .profile-pic,
.page-enter-active .profile-pic {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform: scale(1) translateY(0);
          transform: scale(1) translateY(0); }

.page-appear.page-appear-active .scene-icon-pile p,
.page-enter-active .scene-icon-pile p {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0); }

.page-appear.page-appear-active h1,
.page-enter-active h1 {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0); }

.page-appear.page-appear-active h2,
.page-enter-active h2 {
  opacity: 1;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0); }

.page-leave .page-header .profile-pic {
  opacity: 1; }

.page-leave .page-header h1 {
  opacity: 1; }

.page-leave .page-header h2 {
  opacity: 1; }

.page-leave .page-header button {
  opacity: 1; }

.page-leave.page-leave-active .profile-pic {
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform: scale(0.6) translateY(-100px);
          transform: scale(0.6) translateY(-100px); }

.page-leave.page-leave-active h1 {
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform: scale(0.6) translateX(-400px);
          transform: scale(0.6) translateX(-400px);
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s; }

.page-leave.page-leave-active h2 {
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: scale(0.6) translateX(-400px);
          transform: scale(0.6) translateX(-400px); }

.page-leave.page-leave-active button {
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform: scale(0.6) translateX(-400px);
          transform: scale(0.6) translateX(-400px); }

.page-leave.page-leave-active p {
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
  -webkit-transform: scale(0.6) translateX(-400px);
          transform: scale(0.6) translateX(-400px); }

.page-leave.page-leave-active canvas {
  opacity: 0; }

.page-leave.page-leave-active .next-page {
  right: -76px; }

.page-leave.page-leave-active .prev-page {
  left: -76px; }

.page-leave.page-leave-active .zoom-1::after {
  -webkit-animation: 0.3s zoom-0 ease forwards;
          animation: 0.3s zoom-0 ease forwards;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.page-section.project {
  padding-top: 360px;
  width: 100%;
  background: transparent; }
  @media only screen and (max-width: 450px) {
    .page-section.project {
      padding-top: 252px; } }
  .page-section.project h2 {
    font-size: 1.5em; }
  .page-section.project .timeframe {
    color: #999;
    margin-top: -18px;
    font-size: 1.2em; }
    @media only screen and (max-width: 450px) {
      .page-section.project .timeframe {
        margin-top: -6px; } }

.project-enter {
  -webkit-animation: 0.3s projectEnter ease;
          animation: 0.3s projectEnter ease; }

.project-right-enter {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  opacity: 0; }

.project-right-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-right-leave-active {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-left-enter {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
  opacity: 0; }

.project-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-left-leave-active {
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.project-leave {
  z-index: 0; }

.next-page, .prev-page {
  width: 76px;
  height: 76px;
  background: #000 url(/static/media/icon-chevron.d3901a08.svg) no-repeat center;
  background-size: 18px;
  position: absolute;
  z-index: 15;
  top: 234px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  outline: none;
  background-position-x: 55%;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5); }
  @media only screen and (max-width: 450px) {
    .next-page, .prev-page {
      width: 60px;
      height: 60px;
      background-size: 14px;
      top: 160px;
      -webkit-filter: brightness(1);
              filter: brightness(1); } }

.next-page:hover, .prev-page:hover {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  -webkit-filter: brightness(1);
          filter: brightness(1); }

.next-page:active, .prev-page:active,
.active.next-page,
.active.prev-page {
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.next-page {
  right: -76px; }

.next-page-visible {
  right: 24px; }

.prev-page {
  left: -76px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.prev-page-visible {
  left: 24px; }

.prev-page-visible:hover {
  -webkit-transform: scaleX(-1.08) scaleY(1.08);
          transform: scaleX(-1.08) scaleY(1.08); }

.prev-page:active,
.prev-page.active {
  -webkit-transform: scaleX(-0.8) scaleY(0.8);
          transform: scaleX(-0.8) scaleY(0.8); }

@-webkit-keyframes projectEnter {
  from {
    opacity: 0;
    -webkit-transform: translateY(-96px);
            transform: translateY(-96px);
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes projectEnter {
  from {
    opacity: 0;
    -webkit-transform: translateY(-96px);
            transform: translateY(-96px);
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.project-leave {
  -webkit-animation: 0.3s fadeOutScale ease forwards;
          animation: 0.3s fadeOutScale ease forwards; }

.project .project-details {
  width: 600px;
  margin: 0 auto;
  font-size: 1.25em;
  color: #ccc;
  margin-top: 42px; }

/*
.project::after {
  content: '';
  position: fixed;
  height: 100%;
  background: linear-gradient(to bottom, rgba(18, 18, 18, 0.0), rgba(18, 18, 18, 0.0) 80%, #121212 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}*/

