@import '~scss/constants.scss';
@import '~scss/mixins.scss';

$profile_pic_max: 240px;
$profile_pic_max_mobile: 156px;
$profile_pic_min: 72px;

button {
  border: none;
  background: $button_color;
  border-radius: 36px;
  font-size: 22px;
  color: #fff;
  padding: 18px 36px;
  font-family: 'Ubuntu';
  font-weight: 400;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  background: $button_color linear-gradient(to left, $button_color 0%, $button_color 30%, #FE7A7D 40%, $button_color 60%);
  animation: slide 10s infinite;
  background-position: -140px;
}



#root {
  background: $page_section_header_bg_color;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
}

#root::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: url(~images/page-section-bg.png);
  background-size: 300px;
  opacity: 0.06;
  position: absolute;
  top: 0;
  z-index: 0;
}

#root::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba($page_section_header_bg_color, 0.2), $page_section_header_bg_color);
  position: absolute;
  top: 0;
  z-index: 1;
}

//
// Core scene
//
.scene-icon-pile {
  background: $page_section_header_bg_color;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

.scene-icon-pile::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: url(~images/page-section-bg.png);
  background-size: 300px;
  opacity: 0.06;
  position: absolute;
  top: 0;
  z-index: 1;
}

.scene-icon-pile::before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba($page_section_header_bg_color, 0.2), $page_section_header_bg_color);
  position: absolute;
  top: 0;
  z-index: 2;
}

.scene-icon-pile canvas {
  position: absolute;
  z-index: 10;
}

//
// Page Sections
//
.page-section {
  position: relative;
  overflow: auto;
}
.page-section-inner {
  position: relative;
}

//
// Page Section
//
.page-section {
  z-index: 2;
  text-align: center;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  h1 {
    font-family: 'Poppins';
    font-weight: 500;
    color: white;
    font-size: 2.4em;
    z-index: 3;
    position: relative;

    @include breakpoint($phone) {
      font-size: 2em;
    }
  }
  h2 {
    font-size: 1.8em;
    font-family: 'Poppins';
    font-weight: 400;
    z-index: 3;
    position: relative;
    max-width: 600px;
    padding: 0 18px;
    margin: 0 auto;

    @include breakpoint($phone) {
      font-size: 1.7em;
      padding: 0 12px;
    }
  }
  .profile-pic {
    @include background-2x('~images/profile-photo', 'png', $profile_pic_max, $profile_pic_max, center center, no-repeat);
    width: $profile_pic_max;
    height: $profile_pic_max;
    z-index: 3;
    position: relative;
    margin: 96px auto 40px;

    @include breakpoint($phone) {
      @include background-2x('~images/profile-photo', 'png', $profile_pic_max_mobile, $profile_pic_max_mobile, center center, no-repeat);
      margin: 66px auto 24px;
      width: $profile_pic_max_mobile;
      height: $profile_pic_max_mobile;
    }
  }
  button {
    margin-top: 48px;
  }
  p {
    font-size: 1.3em;
    margin: 18px 0;
    color: #999;
  }
}

.scene-icon-pile {
  .profile-pic {
    @include background-2x('~images/profile-photo-2', 'png', $profile_pic_max, $profile_pic_max, center center, no-repeat);
    @include breakpoint($phone) {
      @include background-2x('~images/profile-photo-2', 'png', $profile_pic_max_mobile, $profile_pic_max_mobile, center center, no-repeat);
    }
  }
}

.scene-section-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -96px;
  z-index: 100;
}

//
// Transition effects
//

// Page header
.page-section.minimized {
  .profile-pic {
    position: relative;
    animation: 0.3s minimizePageHeader ease forwards;
  }
  .profile-pic:hover {
    display: none;
  }
  h1, h2, .button, p {
    animation: 0.3s fadeOutScale ease forwards;  
  }

}
.page-section.maximized {
  .profile-pic {
    animation: 0.3s maximizePageHeader ease forwards;
  }
  h1, h2, .button, p {
    animation: 0.3s fadeInScale ease forwards;  
  }
}

.page-section.initial {
  .profile-pic {
    animation: 0.3s loadPageHeader ease forwards;
  }
  h1, h2, .button, p {
    animation: 0.3s fadeInScale ease forwards;  
  }
}

@keyframes minimizePageHeader {
  0% {
    transform: translateY(0);
    background-size: $profile_pic_max;
    width: $profile_pic_max;
    height: $profile_pic_max;
  }
  
  100% {
    transform: translateY(-42px);
    width: $profile_pic_min;
    height: $profile_pic_min;
    background-size: $profile_pic_min;
  }
}

@keyframes maximizePageHeader {
  0% {
    transform: translateY(-126px);
    background-size: $profile_pic_min;
  }
  100% {
    transform: translateY(0px);
    width: $profile_pic_max;
    height: $profile_pic_max;
    background-size: $profile_pic_max;
  }
}

@keyframes loadPageHeader {
  0% {
    transform: translateY(126px);
    background-size: $profile_pic_min;
  }
  100% {
    transform: translateY(0px);
    width: $profile_pic_max;
    height: $profile_pic_max;
    background-size: $profile_pic_max;
  }
}

@include breakpoint($phone) {
  @keyframes minimizePageHeader {
    0% {
      transform: translateY(0);
      background-size: $profile_pic_max_mobile;
      width: $profile_pic_max_mobile;
      height: $profile_pic_max_mobile;
    }
    
    100% {
      transform: translateY(-42px);
      width: $profile_pic_min;
      height: $profile_pic_min;
      background-size: $profile_pic_min;
    }
  }
  
  @keyframes maximizePageHeader {
    0% {
      transform: translateY(-84px);
      background-size: $profile_pic_min;
    }
    100% {
      transform: translateY(0px);
      width: $profile_pic_max_mobile;
      height: $profile_pic_max_mobile;
      background-size: $profile_pic_max_mobile;
    }
  }
}

//
// Canvas
// 

.zoom-0::after {
  animation: 0.3s zoom-0 ease forwards;
  transform-origin: 0 100%;
}

.zoom-1::after {
  animation: 0.3s zoom-1 ease forwards;  
  transform-origin: 0 100%;
}

@keyframes zoom-1 {
  from {
    background-size: 300px;
    background-position: 0px 0px;
  }
  to {
    background-size: 200px;
    background-position: 200px 200px;
  }
}

@keyframes zoom-0 {
  from {
    background-size: 200px;
    background-position: 200px 200px;
  } to {
    background-size: 300px;
    background-position: 0px 0px;
  }
}

//
// General
//
@keyframes fadeOutScale {
  from {
    opacity: 1;
    transform: translateY(0);
  } to {
    opacity: 0;
    transform: translateY(-96px);
    transform: scale(0.5);
  }
}
@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: translateY(-96px);
    transform: scale(0.5);
  } to {
    opacity: 1;
    transform: scale(1);
    transform: translateY(0);
  }
}

@keyframes slide {
	0% {
    background-position: -140px;
  }
  20% {
    background-position: 80px;
  }
  100% {
    background-position: 100px;
  }
}

//
// Home page transitions
//
.page-enter,
.page-appear {
  button {
    opacity: 0.01;
    transform: scale(0.6) translateX(400px);
  }
  .profile-pic {
    opacity: 0.01;
    transform: scale(0.6) translateY(-100px);;
  }
  .scene-icon-pile {
    p {
      opacity: 0.01;
      transform: scale(0.6) translateX(400px);
    }
  }
  h1 {
    opacity: 0.01;
    transform: scale(0.6) translateX(400px);
  }
  h2 {
    opacity: 0.01;
    transform: scale(0.6) translateX(400px);
  }
}

.page-appear.page-appear-active,
.page-enter-active {
  button {
    opacity: 1;
    transition: all 300ms ease-in-out;
    transition-delay: 0.15s;
    transform: scale(1) translateX(0);
  }
  .profile-pic {
    opacity: 1;
    transition: all 300ms ease-in-out;
    transform: scale(1) translateY(0);;
  }
  .scene-icon-pile {
    p {
      opacity: 1;
      transition: all 300ms ease-in-out;
      transition-delay: 0.3s;
      transform: scale(1) translateX(0);
    }
  }
  h1 {
    opacity: 1;
    transition: all 300ms ease-in-out;
    transform: scale(1) translateX(0);
  }
  h2 {
    opacity: 1;
    transition: all 300ms ease-in-out;
    transition-delay: 0.1s;
    transform: scale(1) translateX(0);
  }
}
.page-leave {
  .page-header {
    .profile-pic {
      opacity: 1;
    }
    h1 {
      opacity: 1;
    }
    h2 {
      opacity: 1;
    }
    button {
      opacity: 1;
    }
  }
}

.page-leave.page-leave-active {
  .profile-pic {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transform: scale(0.6) translateY(-100px);;
  }
  h1 {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transform: scale(0.6) translateX(-400px);
    transition-delay: 0.15s;
  }
  h2 {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transition-delay: 0.1s;
    transform: scale(0.6) translateX(-400px);
  }
  button {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transform: scale(0.6) translateX(-400px);
  }
  p {
    opacity: 0;
    transition: all 300ms ease-in-out;
    transition-delay: 0.15s;
    transform: scale(0.6) translateX(-400px);
  }
  canvas {
    opacity: 0;
  }
  .next-page {
    right: -76px;
  }
  .prev-page {
    left: -76px;
  }
  .zoom-1::after {
    animation: 0.3s zoom-0 ease forwards;
    transform-origin: 0 100%;
  }
}