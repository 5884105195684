@import '~scss/constants.scss';
@import '~scss/mixins.scss';

.page-section.project {
  padding-top: 360px;
  width: 100%;
  background: transparent;
  //z-index: 15;

  @include breakpoint($phone) {
    padding-top: 252px;
  }

  h2 {
    font-size: 1.5em;
  }
  .timeframe {
    color: #999;
    margin-top: -18px;
    font-size: 1.2em;

    @include breakpoint($phone) {
      margin-top: -6px;
    }
  }
}

.project-enter {
  animation: 0.3s projectEnter ease;  
}

.project-right-enter {
  transform: translateX(100px);
  opacity: 0;
}
.project-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.project-right-leave-active {
  transform: translateX(-100px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.project-left-enter {
  transform: translateX(-100px);
  opacity: 0;
}
.project-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.project-left-leave-active {
  transform: translateX(100px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}


.project-leave {
  z-index: 0;
}

%base-page-button {
  width: 76px;
  height: 76px;
  background: #000 url(~images/icon-chevron.svg) no-repeat center;
  background-size: 18px;
  position: absolute;
  z-index: 15;
  top: 234px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
  outline: none;
  background-position-x: 55%;
  filter: brightness(0.5);

  @include breakpoint($phone) {
    width: 60px;
    height: 60px;
    background-size: 14px;
    top: 160px;
    filter: brightness(1);
  }
}

%base-page-button:hover {
  transform: scale(1.08);
  filter: brightness(1);
}

%base-page-button:active,
%base-page-button.active {
  transform: scale(0.8);
}


.next-page {
  @extend %base-page-button;
  right: -76px;
}
.next-page-visible {
  right: 24px;
}

.prev-page {
  @extend %base-page-button;
  left: -76px;
  transform: scaleX(-1);
}

.prev-page-visible {
  left: 24px;
}

.prev-page-visible:hover {
  transform: scaleX(-1.08) scaleY(1.08);
}

.prev-page:active,
.prev-page.active {
  transform: scaleX(-0.8) scaleY(0.8);
}

@keyframes projectEnter {
  from {
    opacity: 0;
    transform: translateY(-96px);
    transform: scale(0.5);
  } to {
    opacity: 1;
    transform: scale(1);
    transform: translateY(0);
  }
}

.project-leave {
  animation: 0.3s fadeOutScale ease forwards;  
}

.project {
  .project-details {
    width: 600px;
    margin: 0 auto;
    font-size: 1.25em;
    color: #ccc;
    margin-top: 42px;
  }
}

/*
.project::after {
  content: '';
  position: fixed;
  height: 100%;
  background: linear-gradient(to bottom, rgba(18, 18, 18, 0.0), rgba(18, 18, 18, 0.0) 80%, #121212 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}*/